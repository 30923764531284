const host = import.meta.env.VITE_ENV ?? window.location.hostname

const environment: Record<string, string> = {
  'localhost': 'local',
  'dev': 'development',
  'staging': 'staging',
  'prod': 'production'
}

const getEnvironment = ( host: string ): string => {
  if ( host.includes( 'localhost' ) ) return 'localhost'
  if ( host.includes( 'development' ) ) return 'dev'
  if ( host.includes( 'staging' ) ) return 'staging'
  if ( host === 'abilibee.app' ) return 'prod'
  return 'local'
}

const env = environment[getEnvironment( host )]

export default env
