import Http from '@/assets/ts/classes/Http'
import { Notification } from '@/assets/ts/interfaces/Notification'

const useNotification = () => {
  const getNotifications = async( user_id: number ) => {
    const notifications: Notification[] = await Http.get( '/notifications?user_id=' + user_id )

    const { translate } = useDeepL()
    const enTitles = notifications.map( notification => notification.title )
    const translatedTitles = await translate( enTitles.join( '§' ) )

    const enContents = notifications.map( notification => notification.content )
    const translatedContents = await translate( enContents.join( '§' ) )

    notifications.forEach( ( notification, i ) => {
      notification.title = translatedTitles.split( '§' )[ i ]
      notification.content = translatedContents.split( '§' )[ i ]
    } )

    return notifications
  }

  const readNotifications = ( ids: number[] ) => Http.patch( '/notifications', { 'notification_ids': ids } )

  return {
    getNotifications,
    readNotifications
  }

}

export default useNotification