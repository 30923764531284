import Http from '@/assets/ts/classes/Http'

type UserInput = {
    id: number;
    nickname: string;
    firstName: string;
    lastName: string;
    email: string;
    birthdate: string;
    zip: string;
    state: string;
    avatar?: number;
    language: string;
    password?: string;
    interests: Record<string,boolean>;
}

const useUser = () => {
  const getUser = async () => Http.get( '/user' )
  const getChildren = async () => Http.get( '/impersonation' )
  const updateUser = async ( data: UserInput ) => Http.patch( '/user', data )

  return {
    getUser,
    getChildren,
    updateUser
  }
}

export default useUser
