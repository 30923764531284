import { defineStore } from 'pinia'
import { ref } from 'vue'
import { Interest } from '@/assets/ts/api/useInterest'
import useUser from '@/assets/ts/api/useUser'
import { loadLanguageAsync } from '@/modules/i18n'

type User = {
  id: number;
  nickname: string;
  firstName: string;
  lastName: string;
  email: string;
  birthdate: string;
  zip: string;
  state: string;
  avatar: string;
  language: string;
  interests: Interest[];
};

type Child ={
  id: number;
  firstName: string;
  lastName: string;
  birthdate: string;
}

export const useUserStore = defineStore( 'user', () => {
  /**
   * State
   */
  const user = ref<User>( )
  const children = ref<Child[]>( [] )
  const selectedUserId = ref<number>( 0 )

  /**
   * Init function to fetch data from the service
   */
  async function init() {
    if( ! user.value && localStorage.getItem( 'abilibeeToken' ) ) {
      const { getUser, getChildren } = useUser()
      user.value = await getUser()
      children.value = ( await getChildren() )?.children || []
      loadLanguageAsync( user.value?.language?.split( '_' )[0] ?? 'en' )

      if( children.value.length > 0 ) {selectedUserId.value =  children.value[0].id} else {
        selectedUserId.value = user.value?.id ?? 0
      }
    }
  }

  async function refetch() {
    const { getUser, getChildren } = useUser()
    user.value = await getUser()
    children.value = ( await getChildren() ).children ?? []
    loadLanguageAsync( user.value?.language?.split( '_' )[0] ?? 'en' )
  }

  return { user, children, selectedUserId, init, refetch }
} )