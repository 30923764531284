import { IonicVue } from '@ionic/vue'
import { createPinia } from 'pinia'
import PortalVue from 'portal-vue'
import VCalendar from 'v-calendar'
import { createApp } from 'vue'
import '@/style.css'
import App from '@/App.vue'
import BaseLayout from '@/components/BaseLayout.vue'
import {
  i18n,
  loadLanguageAsync,
} from '@/modules/i18n'
import router from '@/router'

// import 'uno.css'

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'

import '@/assets/css/theme.css'
import '@/assets/css/index.css'
import '@/assets/css/transitions.css'
import 'v-calendar/style.css'

const pinia = createPinia()

const app = createApp( App )
  .use( IonicVue )
  .use( i18n )
  .use( router )
  .use( pinia )
  .use( PortalVue )
  .use( VCalendar )

app.component( 'BaseLayout', BaseLayout )

router.isReady().then( () => app.mount( '#app' ) )
loadLanguageAsync( 'en' )
