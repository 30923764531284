<template>
  <button
    class="btn"
    :class="`relative ${variant} ${size} ${roundedFull ? 'rounded-full' : 'rounded-lg'}`"
  >
    <div
      v-if="loading"
      class="absolute inset-0 w-full h-full flex z-30"
      :class="`${variant} ${size} ${roundedFull ? 'rounded-full' : 'rounded-lg'}`"
    >
      <div
        class="flex items-center justify-center w-full"
      >
        <Spinner
          :fill="variant === 'primary' ? 'fill-white' : undefined"
          :size="size === 'xs' ? 4 : undefined"
        />
      </div>
    </div>

    <div
      class="flex justify-center items-center gap-1.5 relative"
      :class="{ 'flex-row-reverse': iconBefore }"
    >
      <p class="m-0 font-bold">
        {{ title }}
      </p>
      <BaseIcon
        is="props.icon"
        :class="props.iconClass ?? 'w-4.5'"
        :icon="props.icon"
        :color="props.color"
      />
      <IonIcon
        v-if="ionIcon"
        class="mis-1"
        :icon="ionIcon"
      />
    </div>
  </button>
</template>

<script setup lang="ts">
import { IonIcon } from '@ionic/vue'
import { computed } from 'vue'
import BaseIcon from '@/components/BaseIcon.vue'
import Spinner from '@/components/Spinner.vue'

type ButtonVariants = 'primary' | 'secondary' | 'tertiary' | 'remove'
type IconPlacement = 'before' | 'after'
type SizeVariants = 'small' | 'xs'

const props = defineProps<{
  title: string;
  variant: ButtonVariants;
  color?: string;
  iconPlacement?: IconPlacement;
  icon?: any;
  ionIcon?: string;
  iconClass?: string
  size?: SizeVariants
  roundedFull?: boolean;
  loading?: boolean;
}>()

const iconBefore = computed(
  () => props.icon && props.iconPlacement === 'before'
)
</script>

<style scoped>
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn {
  @apply
  hover:brightness-90
  py-3
  px-5
  text-base
  w-full
}

.primary {
  @apply
  bg-brand-primary-yellow
}

.xs {
  @apply
  py-1
  px-5
  text-sm
}

.small {
  @apply
  p-2
}

.remove {
  @apply
  bg-red-700
  text-white
}

.secondary {
  outline: #bbb 2px solid;
  outline-offset: -2px;

  @apply
  bg-white
  w-full
}

.icon {
  align-self: center;
  padding-inline: 2px;
  height: 100%;
}
</style>
