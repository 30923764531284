<template>
  <ion-page>
    <ion-header
      v-if="!hideHeader"
      :translucent="true"
    >
      <ion-toolbar>
        <ion-tabs>
          <ion-router-outlet />
          <ion-tab-bar slot="top">
            <div class="flex justify-between w-full items-center mx-5">
              <div class="flex items-center gap-2 group">
                <BaseIcon
                  class="w-8 h-8"
                  :icon="abilibee"
                  @click="() => router.push( '/dashboard' )"
                />
                <span
                  class="text-xl text-black opacity-0 group-hover:opacity-100 transform transition-opacity duration-300 font-semibold"
                >abilibee</span>
              </div>
              <div class="flex gap-7 items-center mr-3 fill-black">
                <BaseIcon
                  class=""
                  :icon="favourites"
                  @click="() => viewFavorites = true"
                />
                <BaseIcon
                  :icon="notification"
                  @click="() => viewNotifications = true"
                />
              </div>
            </div>
          </ion-tab-bar>
        </ion-tabs>
        <ion-buttons>
          <slot name="actions-start" />
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <slot />
    </ion-content>

    <BaseSideBlade
      v-if="viewNotifications"
      full-content
      @close="viewNotifications = false"
    >
      <ViewNotifications />
    </BaseSideBlade>

    <BaseSideBlade
      v-if="viewFavorites"
      full-content
      @close="viewFavorites = false"
    >
      <ViewFavorites @close="viewFavorites = false" />
    </BaseSideBlade>
  </ion-page>
</template>

<script setup lang="ts">
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabs,
  IonToolbar
} from '@ionic/vue'
import { useRouter } from 'vue-router'
import abilibee from '@/assets/images/icons/abilibee.vue'
import favourites from '@/assets/images/icons/favourites.vue'
import notification from '@/assets/images/icons/notification.vue'
import BaseIcon from '@/components/BaseIcon.vue'
import BaseSideBlade from '@/components/BaseSideBlade.vue'
import ViewFavorites from '@/components/blades/ViewFavorites.vue'
import ViewNotifications from '@/components/blades/ViewNotifications.vue'

const viewNotifications = ref( false )
const viewFavorites = ref( false )

defineProps( {
  hideBackButton: Boolean,
  hideHeader: Boolean,
  pageDefaultBackLink: String,
  pageTitle: String,
} )

const router = useRouter()
</script>
