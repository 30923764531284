import { defineStore } from 'pinia'
import {
  computed,
  ref
} from 'vue'

export const useFavouriteStore = defineStore( 'favourite', () => {
  /**
   * State
   */
  const favourites = ref<{ID: number, title?: string, postType?: string, type?:string, url?: string}[]>( )

  /**
   * Actions
   */
  async function addFavourite( ID: number, title = '', postType = '', type = '', url = '' ) {
    const backup = [ ...favourites?.value ?? [] ]
    favourites.value?.push( { ID, title, postType, type, url } )

    const { addFavourite } = useFavourites()
    await addFavourite( ID ).catch( () => {
      favourites.value = backup
    } )
  }

  async function removeFavourite( ID: number ) {
    if( favourites.value ){
      const backup = [ ...favourites?.value ?? [] ]

      const index = favourites.value.findIndex( ( s ) => s.ID === ID )
      if ( index !== - 1 ) {
        favourites.value.splice( index, 1 )
      }

      const { removeFavourite } = useFavourites()
      await removeFavourite( ID ).catch( () => {
        favourites.value = backup
      } )
    }
  }

  /**
   * Getters
   */
  const isFavourite = computed( () => ( ID: number ) => favourites.value?.some( s => s.ID === ID ) ?? false )

  /**
   * Init function to fetch data from the service
   */
  async function init() {
    if( ! favourites.value ) {
      const { getFavourites } = useFavourites()
      favourites.value = await getFavourites()
    }
  }

  return { favourites, addFavourite, removeFavourite, isFavourite, init }
} )