const ENV: Record<string, Record<string, string>> = {
  local: {
    rootURL: 'http://abilibee-api.local',
    clientID: 'tRh23duUWcFxBvpI6tupY6vWXRSs4nYoe6VNPUA8',
  },
  development: {
    rootURL: 'https://abilibeeapidev.wpenginepowered.com',
    clientID: 'tRh23duUWcFxBvpI6tupY6vWXRSs4nYoe6VNPUA8',
  },
  staging: {
    rootURL: '',
    clientID: ''
  },
  production: {
    rootURL: 'https://abilibeeapi.wpenginepowered.com',
    clientID: 'FGfdN76WXPdvdAa1r3it29mAFK60iTtfLc4Q7SYp'
  }
}

export default ENV
