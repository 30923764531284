import Http from '@/assets/ts/classes/Http'

const useFavourite = () => {
  /**
 * Fetches favourites.
 *
 * @returns {Promise<{ID: number}[]>} Favourites array.
 */
  const getFavourites = (): Promise<{ID: number, title: string, postType: string}[]> => Http.get( '/favourites' )

  /**
 * Adds a favourite.
 *
 * @param {number} ID - Item ID.
 */
  const addFavourite = ( ID: number ) => Http.post( '/favourites', { ID } )

  /**
 * Removes a favourite.
 *
 * @param {number} ID - Item ID.
 */
  const removeFavourite = ( ID: number ) => Http.delete( '/favourites', { ID } )

  return {
    getFavourites,
    addFavourite,
    removeFavourite,
  }
}

export default useFavourite
